import './styles/front/css/vuetify-custom.css';
import './styles/front/version_3/css/normalize.css';
import './styles/front/version_3/css/webflow.css';
import './styles/front/version_3/css/bookfolio.webflow.css';

import './styles/front/version_3/js/webflow.js';
import './vuejs/front/main.js';
import GLightbox from 'glightbox';
//const routes = require("../public/js/fos_js_routes.json");
//import Routing from "../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

window.addEventListener('DOMContentLoaded', (event) => {
    const lightbox = GLightbox({
        selector: 'data-glightbox',
        descPosition: 'right',
    });

    lightbox.on('slide_changed', ({ prev, current }) => {
        $.ajax({
            url: Routing.generate("portfolio_setviewmedias", {
                name: name,
                image: current.trigger.id,
            }),
            type: "POST",
            data: current.trigger.id,
            success: function (response) {
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
            },
        });
    });
});

!function (o, c) {
    var n = c.documentElement, t = " w-mod-";
    n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);